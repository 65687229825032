/* globals analytics window */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { Button, Navbar, NavItem } from 'react-materialize';
import Moment from 'moment';

import { clearAccessToken, hasAccessToken } from '../../brainApi';
import ChurnedModal from '../ChurnedModal';
import {
  tracRudderStackkEvent,
  currentPageInfo,
  setCookie,
  getCookie,
} from '../../helper';

class Zippity extends Component {
  componentDidMount() {
    const {
      dispatch,
      account,
      referrer,
      scheduledWorks,
      z3pConfiguration,
      cart,
    } = this.props;
    const { customer_id: customerId, password, ...profile } = account;
    const {
      z3p_client_name: z3pEnv,
      google_analytics_tracking_id,
    } = z3pConfiguration;

    if (z3pEnv !== 'zippity') {
      window.intercomSettings = {
        hide_default_launcher: true,
      };
    }

    console.log('environment ', process.env.REACT_APP_ENVIRO);
    const { location } = window;
    const query = location.search.substring(1);
    let pair = query.split('=');

    let serviceId = '';
    if (pair[0] === 'service') {
      serviceId = decodeURIComponent(pair[1]);
      dispatch({ type: 'SET_SERVICE_ID', urlLoadServiceId: serviceId });
    }

    const params = query.split('&');
    let rwgToken = '';
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < params.length; ++i) {
      pair = params[i].split(/=(.+)/);
      if (pair[0] === 'rwg_token') {
        rwgToken = decodeURIComponent(pair[1]);
        break;
      }
    }

    if (rwgToken !== '') {
      setCookie('_rwg_token', rwgToken, 30);
    }
    // Send page view information to GTM - Pros need to set up a custom pageview trigger from GTM to GA
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: document.location.pathname,
        title: document.title,
      },
    });

    tracRudderStackkEvent('Zippity_page', {
      proname: z3pConfiguration.z3p_client_name,
      // eslint-disable-next-line no-undef
      path: window.location.pathname,
      url: window.location.href,
      title: document.title
        ? document.title
        : z3pConfiguration.customer_facing_name,
      referrer: document.referrer,
      search: '',
      keywords: '',
      integrations: '',
    });

    // For Pros with Google Analytics, add gtag directly
    if (
      google_analytics_tracking_id &&
      window &&
      window.gtag &&
      window.location &&
      window.location.pathname
    ) {
      window.gtag('config', google_analytics_tracking_id, {
        page_path: window.location.pathname,
      });
    }

    if (referrer.code) {
      profile.referrer_code = referrer.code;
      profile.referrer_source = referrer.source || '';
    }

    if (scheduledWorks && scheduledWorks.length) {
      profile.count_scheduled_services = scheduledWorks.length;
    }

    // Hack to make the logo link open new tab
    window.document
      .querySelector('.brand-logo')
      .setAttribute('target', '_blank');
  }

  displayToasts = (dispatch, messages) => {
    // Check any pending alert messages / toasts
    Object.values(messages).forEach(
      ({ message, expirationTime, messageType, pageToDisplayOn }) => {
        // Some messages should only display on a certain page, some should persist
        if (pageToDisplayOn && pageToDisplayOn != window.location.href) {
          dispatch({
            type: 'REMOVE_MESSAGE',
            message,
          });
          return;
        }
        // How many seconds until this toast should disappear?
        const secondsUntilExpiration = new Moment(expirationTime).diff(
          new Moment(),
          'seconds',
        );

        // If the toast already exists and there is time until it expires, do nothing
        if (
          document.getElementById(`toast:${message}`) &&
          secondsUntilExpiration > 0
        )
          return;

        // If the toast doesn't exist and has time until it expires, create toast
        if (secondsUntilExpiration > 0) {
          // By default toasts are styled as success messages
          let toastContent = {
            html: `<i class="material-icons alert-icons">check</i><strong id="toast:${message}">${message}</strong>`,
            classes: 'black-text green lighten-4',
          };
          if (messageType === 'error')
            toastContent = {
              html: `<i class="material-icons alert-icons">clear</i><strong id="toast:${message}">${message}</strong>`,
              classes: 'black-text red lighten-4',
            };
          toastContent.displayLength = secondsUntilExpiration * 1000;
          toastContent.inDuration = 0;
          window.M.toast(toastContent);
        }
        // If the toast is expired, remove it from the store
        else {
          dispatch({
            type: 'REMOVE_MESSAGE',
            message,
          });
        }
      },
    );
  };

  handleLogin = () => {
    const { z3pConfiguration } = this.props;
    tracRudderStackkEvent('Zippity_Log_in', {
      proname: z3pConfiguration.z3p_client_name,
      // eslint-disable-next-line no-undef
      description: `User clicked the login button on ${document.title} page`,
      button_name: 'login',
      account_type: '',
      account_id: '',
      page_information: {
        path: window.location.pathname,
        url: window.location.href,
        // eslint-disable-next-line no-undef
        title: document.title,
      },
    });

    const WHITELIST = [
      '/quote/',
      '/pricing/simple',
      '/pricing/services',
      '/pricing/diagnostic',
    ];

    const currentPath = window.location ? window.location.pathname : '';
    if (
      currentPath &&
      !WHITELIST.every((item) => currentPath.indexOf(item) < 0)
    ) {
      const redirect = encodeURIComponent(currentPath);
      return browserHistory.push(`/login?redirect=${redirect}`);
    }
    return browserHistory.push('/login');
  };

  handleLogout() {
    const { dispatch } = this.props;
    dispatch({ type: 'CLEAR_USER' });
    clearAccessToken();

    browserHistory.push('/');
  }

  render() {
    const {
      account,
      containerId,
      children,
      dispatch,
      messages,
      isPostalCodeInUV,
      z3pConfiguration,
    } = this.props;
    const {
      z3p_client_name: z3pEnv,
      client_url,
      logo,
      customer_facing_name,
      logo_max_height,
      logo_margin_top,
      call_forwarding_phone_raw,
      customer_facing_email,
      customer_facing_address,
    } = z3pConfiguration;

    this.displayToasts(dispatch, messages);

    const brandLogo = (
      <ul>
        <li>
          {logo ? (
            <img
              src={logo}
              alt={customer_facing_name}
              style={{
                maxHeight: logo_max_height,
                marginTop: logo_margin_top,
                cursor: 'pointer',
              }}
              onClick={() => window.open(client_url, '_blank')}
            />
          ) : (
            <div
              style={{
                maxHeight: logo_max_height,
                marginTop: logo_margin_top,
                cursor: 'pointer',
              }}
              onClick={() => window.open(client_url, '_blank')}
            >
              {customer_facing_name}
            </div>
          )}
        </li>
      </ul>
    );

    return (
      <div>
        {call_forwarding_phone_raw ||
        customer_facing_email ||
        customer_facing_address ? (
          <div className="top-bar">
            <div className="container">
              <div className="col s6 contact_items">
                {call_forwarding_phone_raw ? (
                  <div className="contact_icon contact_phone">
                    <span className="fa fa-phone" />
                    <a href={`tel:${call_forwarding_phone_raw}`}>
                      {call_forwarding_phone_raw}
                    </a>
                  </div>
                ) : (
                  ''
                )}
                {customer_facing_email ? (
                  <div className="contact_icon contact_email">
                    <span className="fa fa-envelope-o" />
                    <a href={`mailto:${customer_facing_email}`}>
                      {customer_facing_email}
                    </a>
                  </div>
                ) : (
                  ''
                )}
                {customer_facing_address ? (
                  <div className="contact_icon">
                    <span className="fa fa-map-marker" />
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,no-script-url */}
                    <a href="javascript:">{customer_facing_address}</a>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        <div className="zippity-header">
          <div className="container">
            <Navbar
              href="https://zippitycars.com"
              brand={brandLogo}
              alignLinks="right"
            >
              <div className="headerContact hidden">
                {call_forwarding_phone_raw ? (
                  <div>
                    {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                    <a
                      href={`tel:${call_forwarding_phone_raw}`}
                      rel="noopener noreferrer"
                      className="fa fa-phone contact_icon"
                    />
                    <div className="contact_text_block">
                      <span>Call</span>
                      <a href={`tel:${call_forwarding_phone_raw}`}>
                        {call_forwarding_phone_raw}
                      </a>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {customer_facing_email ? (
                  <div>
                    {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                    <a
                      href={`mailto:${customer_facing_email}`}
                      rel="noopener noreferrer"
                      className="fa fa-envelope-o contact_icon"
                    />
                    <div className="contact_text_block">
                      <span>Email</span>
                      <a href={`mailto:${customer_facing_email}`}>
                        {customer_facing_email}
                      </a>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {customer_facing_address ? (
                  <div>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/anchor-has-content */}
                    <a
                      href="#"
                      rel="noopener noreferrer"
                      className="fa fa-map-marker contact_icon"
                    />
                    <div className="contact_text_block">
                      <span>Address</span>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a href="#" className="address">
                        {customer_facing_address}
                      </a>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              {hasAccessToken() && (
                <ul>
                  <li className="nav-item hi-user">
                    <strong>Hey, {account.first_name}! &nbsp;&nbsp;</strong>
                  </li>
                  <li className="nav-space">
                    <span style={{ width: '1em', display: 'block' }} />
                  </li>
                </ul>
              )}

              {z3pEnv === 'zippity' && isPostalCodeInUV && (
                <NavItem
                  className="nav-item"
                  target="_blank"
                  href="https://booking.uvngo.com/"
                >
                  Detailing
                </NavItem>
              )}

              {z3pEnv === 'zippity' && (
                <NavItem
                  className="nav-item"
                  target="_blank"
                  href="https://zippitycars.com/faq"
                >
                  FAQ
                </NavItem>
              )}

              {hasAccessToken() && (
                <NavItem
                  className={
                    window.location.pathname === '/account'
                      ? 'nav-item hover-underline-animation active'
                      : 'nav-item hover-underline-animation'
                  }
                  href="/account"
                >
                  Appointments
                </NavItem>
              )}

              {hasAccessToken() && (
                <NavItem
                  className={
                    window.location.pathname === '/settings'
                      ? 'nav-item hover-underline-animation active'
                      : 'nav-item hover-underline-animation'
                  }
                  href="/settings"
                >
                  My Account
                </NavItem>
              )}

              <span className="nav-item">
                {hasAccessToken() ? (
                  <Button onClick={() => this.handleLogout()}>Log Out</Button>
                ) : (
                  <Button onClick={() => this.handleLogin()}>Log In</Button>
                )}
              </span>
            </Navbar>
          </div>
        </div>

        <main>
          <div className="container clearfix" id={containerId}>
            <ChurnedModal />
            {children}
          </div>
        </main>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    pricing: state.ui.pricing,
    cart: state.ui.cart,
    account: state.ui.account,
    referrer: state.ui.referrer,
    scheduledWorks: state.ui.scheduledWorks,
    messages: state.ui.messages,
    isPostalCodeInUV: state.ui.isPostalCodeInUV,
    z3pConfiguration: state.ui.z3pConfiguration,
    urlLoadServiceId: state.ui.urlLoadServiceId,
  };
}

export default connect(mapStateToProps, null)(Zippity);
